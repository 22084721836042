import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";
import { updateOneDemande } from "../../store/demandeSlice";
import Select from "react-select";
const ModalConfirmationDemande = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [selectType, setSelectType] = useState();
  const [selectCountry, setSelectCountry] = useState();
  const [selectAllCountry, setSelectAllCountry] = useState();
  const [numberChannel, setNumberChanel] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [selectTypeAcces, setSelectTypeAcces] = useState();
  const [TrunkName, setTrunkName] = useState("");
  const [numTrunkSelected, setNumTrunKSelected] = useState([]);
  const [numDispo, setNumDispo] = useState([]);
  const [trunkId, setTrunkId] = useState();
  const [description, setDescription] = useState("");
  const typeRequest = [
    {
      value: 1,
      type: "numero",
    },
    {
      value: 2,
      type: "acces",
    },
    {
      value: 3,
      type: "trunk",
    },
    {
      value: 4,
      type: "Modification Trunk",
    },
  ];
  const count = [
    {
      name: "France",
    },
    {
      name: "Belgique",
    },
    {
      name: "Canada",
    },
    {
      name: "Autres",
    },
  ];
  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Cote d'Ivoire (Ivory Coast)",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    'Eswatini (fmr. "Swaziland")',
    "Ethiopia",
    "Fiji",
    "Finland",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  const type = [
    {
      value: 1,
      type: "fixe",
    },
    {
      value: 2,
      type: "mobile",
    },
    {
      value: 3,
      type: "fixe + mobile",
    },
  ];
  const verificationType = (type) => {
    switch (type) {
      case "Modification Trunk":
        return 4;
      case "Trunk":
        return 3;
      case "Accés":
        return 2;
      case "Numéro":
        return 1;
      default:
        return 1;
    }
  };
  useEffect(() => {
    if (props.data) {
      setNumberChanel(props.data.canaux);
      setSelectType(verificationType(props.data.type));
      setSelectTypeAcces(props.data.typeNumero);
      setQuantity(props.data.quantite);
      setNumberChanel(props.data.canaux);
      setSelectCountry(props.data.pays);
      setDescription(props.data.description ? props.data.description : "");
      if (props?.data?.trunkNumeros && props?.data?.trunkNumeros.length > 0) {
        const updatedOptions = props?.data?.trunkNumeros.map((numero) => ({
          value: numero._id,
          label: numero.numero,
        }));
        setNumTrunKSelected(updatedOptions);
      }
      setTrunkId(props.data.trunkId?._id);
      setTrunkName(props.data.trunkId?.label);
    }
  }, [props.data]);

  const handleSave = (statut) => {
    updateOneDemande(userInfo.token, {
      _id: props.data?._id,
      statut: statut,
      description: description,
    })
      .then((res) => {
        if (res.data) {
          let obj = res.data;
          const newObj = {
            ...obj,
            type:
              obj.type === 1
                ? "Numéro"
                : obj.type === 2
                  ? "Accés"
                  : obj.type === 3
                    ? "Trunk"
                    : obj.type === 4
                      ? "Modification Trunk"
                      : obj.type,
            statut:
              obj.statut === 1
                ? "En cours"
                : obj.statut === 2
                  ? "Validée"
                  : obj.statut === 3
                    ? "Annulée"
                    : obj.statut === 4
                      ? "Refusée"
                      : obj.statut,
          };
          addToast(`Demande ${newObj.statut ? newObj.statut : "enregistrer"}`, {
            appearance: "success",
            autoDismiss: true,
          });
          props.cell.getRow().update(newObj);
          props.onHide();
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Une erreur s'est produite", {
          appearance: "error",
          autoDismiss: true,
        });
        props.onHide();
      });
  };
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
  }, []);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-create-budget"
    >
      <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <h5>Confirmation Demande</h5>
        <button
          className="btn"
          onClick={(ev) => {
            props.onHide();
          }}
        >
          <i class="bi bi-x"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="modal-create-budget-body">
        <Form>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="4">
                <Form.Label>Type:</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Select size="lg" value={selectType} disabled>
                  {typeRequest.map((type, index) => (
                    <option key={index} value={type.value}>
                      {type.type}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          {selectType != 1 && selectType != 4 && (
            <>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <Form.Label>Pays:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <Form.Select size="lg" value={selectCountry} disabled>
                      {count.map((type, index) => (
                        <option key={index} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}

          {selectType != 1 && selectType != 4 && (
            <>
              {selectCountry === "Autres" ? (
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm="4">
                      <Form.Label>Autres:</Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Select value={selectAllCountry} disabled>
                        <option value="">Sélectionnez un pays</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}
            </>
          )}
          {selectType != 1 && selectType != 4 && (
            <>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <Form.Label> Type accès:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <Form.Select size="lg" value={selectTypeAcces} disabled>
                      {type.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.type}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          {selectType == 3 && selectType != 4 && (
            <>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <Form.Label> Nombre de canaux:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <div className="input-group">
                      <Form.Control
                        required
                        type="number"
                        placeholder="Entrez le nombre de canaux"
                        value={numberChannel}
                        className="custom-form-control"
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez entrer une quantité valide.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          {selectType != 4 && (
            <>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <Form.Label>Quantité:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <div className="input-group">
                      <Form.Control
                        required
                        type="number"
                        disabled
                        placeholder="Entrez votre quantité"
                        value={quantity}
                        className="custom-form-control"
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez entrer une quantité valide.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          {selectType == 4 && (
            <>
              <Form.Group className="mb-3">Trunk : {TrunkName}</Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="12">
                    <Form.Label>Liste numéro attaché et disponible:</Form.Label>
                  </Col>
                  <Col sm="12">
                    <Select
                      value={numTrunkSelected}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={true}
                      isMulti
                      options={numDispo ? numDispo : null}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          <Form.Group className="mb-3">
            <Row>
              <Col sm="12">
                <Form.Label> Ajouter une description:</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <textarea
                  className="form-control"
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  defaultValue={description}
                  onChange={(ev) => {
                    setDescription(ev.target.value);
                  }}
                ></textarea>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-danger"
          onClick={() => {
            props.onHide();
          }}
        >
          Fermer
        </button>
        <button
          type="button"
          class="btn"
          style={{ color: "white", backgroundColor: "gray" }}
          onClick={() => handleSave(4)}
        >
          Refuser
        </button>
        <button
          type="button"
          class="btn"
          style={{ color: "white", backgroundColor: "#6cc070" }}
          onClick={() => handleSave(2)}
        >
          Valider
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmationDemande;
