import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import FiltreTrunk from "./FiltreTrunk";
import {
  simpleInput,
  buttonParametrage,
  simpleInputClientDemande,
} from "../../components/tabulator/tabulator";
import { getListTrunk } from "../../store/trunkSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import ModalModification from "./ModalModification";
import ModalAddTrunk from "./ModalAddTrunk";
import { getListClient } from "../../store/clientSlice";
import { searchOptimizedData } from "../../utils/recherche";
import moment from "moment";

const Trunk = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [endDate, setEndDate] = useState(startDateFormate);
  const [startDate, setStartDate] = useState(dateFormateeOneWeeBefore);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listTrunk = useSelector((state) => state.listTrunk);
  const { error, data, statusListTrunk } = listTrunk;
  const [dataRequest, setDataRequest] = useState([]);
  const [modification, setModification] = useState(false);
  const [addTrunk, setAddTrunk] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState();
  const [cell, setCell] = useState();
  const [bool, setBool] = useState(false);
  //Colonne react tabulator numero
  const colonneTrunk = [
    { title: "Label", field: "label", formatter: simpleInput },
    { title: "client", field: "nbAppel", formatter: simpleInputClientDemande },
    { title: "budget(€)", field: "moneyBudget", formatter: simpleInput },
    { title: "Consommation", field: "consommation", formatter: simpleInput },
    { title: "Nb numéros", field: "nbNumero", formatter: simpleInput },
    { title: "Alerte du jour", field: "nbAlerte", formatter: simpleInput },
    {
      title: "Plus",
      width: 100,
      field: "",
      formatter: (cell, a, b) => {
        return buttonParametrage(
          cell,
          setModification,
          setDataToUpdate,
          setCell
        );
      },
    },
  ];
  const fieldsToFilter = [
    "createdAt",
    "label",
    "nom",
    "prenom",
    "trunkServerName",
    "consommation",
    "moneyBudget",
    "timeBudget",
  ];
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    const momentDateDebut = moment(startDate, "YYYY-MM-DD HH:mm:ss");
    const momentDateFin = moment(endDate, "YYYY-MM-DD HH:mm:ss");
    if(momentDateDebut.isSameOrBefore(momentDateFin)){
      dispatch(getListTrunk({ token: userInfo.token,query:`calldate[gte]=${startDate}&calldate[lte]=${endDate}` }));
    }else{
      alert("la date de debut doit etre inférieur ou égal à la date de fin");
    }
  }, [bool,endDate,startDate]);
  useEffect(() => {
    if (data.list && data.list.length > 0) {
      setDataRequest(
        data.list.map((el) => {
          return {
            ...el,
            nbNumero: el.numeros?.length > 0 ? el.numeros?.length : 0,
            nbAlerte: el.todayAlerts?.length > 0 ? el.todayAlerts?.length : 0,
          };
        })
      );
    } else {
      setDataRequest([]);
    }
  }, [data]);
  useEffect(() => {
    dispatch(getListClient({ token: userInfo.token }));
  }, []);
  useEffect(() => {
    if (statusListTrunk == statusCode.ERROR) {
      addToast(`${error}`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [statusListTrunk]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">/ Gestion Trunk /</h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des Trunks -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} trunk(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6cc070 ",
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                data-bs-toggle="offcanvas"
                data-bs-target="#filtreTrunk"
              >
                <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
                <span>Filtres</span>{" "}
              </button>
              <button
                className="btn btn-sm btn-primary"
                style={{
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(ev) => setAddTrunk(true)}
              >
                <i class="bi bi-plus" style={{ fontSize: "14px" }}></i>
                <span>Ajouter</span>{" "}
              </button>
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataRequest,
                      fieldsToFilter
                    );
                    setDataRequest(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListTrunk == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneTrunk}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 5,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
          <FiltreTrunk
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
          {modification && (
            <ModalModification
              show={modification}
              onHide={() => {
                setModification(false);
              }}
              data={dataToUpdate}
              cell={cell}
            />
          )}
          {addTrunk && (
            <ModalAddTrunk
              show={addTrunk}
              onHide={() => {
                setAddTrunk(false);
              }}
              bool={bool}
              setBool={setBool}
            />
          )}
        </Row>
      </Container>
    </>
  );
};

export default Trunk;
