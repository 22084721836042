import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";

export const getListClient = createAsyncThunk("client/list", async (data) => {
  const config = {
    headers: {
        Authorization: `Bearer ${data.token}`, 
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/infos`,
      config
    );
    return data ? data : [];
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});
const initialState = {
  data: {},
  statusListClient: statusCode.IDLE,
  error: "",
};
const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListClient.pending, (state, action) => {
        state.statusListClient = statusCode.LOADING;
      })
      .addCase(getListClient.fulfilled, (state, action) => {
        state.data = action.payload;
        state.statusListClient = statusCode.IDLE;
      })
      .addCase(getListClient.rejected, (state, action) => {
        state.statusListClient = statusCode.statusListClient;
        state.error = action.error.message;
      });
  },
});

export default clientSlice.reducer;
//export const { addClient, getAllClient } = clientSlice.actions;
