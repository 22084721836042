import React, { Component } from 'react';

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    console.error('Une erreur est survenue:', error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
