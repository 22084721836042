import React from 'react'
import moment from 'moment';
const FiltreTrunk = (props) => {
  return (
    <div class="offcanvas offcanvas-end" id="filtreTrunk">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Filtre</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="simpleTexte">
            Consommation du:
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.startDate && props.startDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setStartDate(newValue);
              }}
            />
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.endDate && props.endDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setEndDate(newValue);
              }}
            />
          </div>
        </div>
      </div>
  )
}

export default FiltreTrunk