import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";
import { updateTrunk } from "../../store/trunkSlice";
const ModalModification = (props) => {
  const { addToast } = useToasts();
  const listClient = useSelector((state) => state.listClient);
  const { data } = listClient;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [label, setLabel] = useState();
  const [trunkServerName, setTunkServerName] = useState("");
  const [idClient, setIdClient] = useState();
  const [dataClient, setDataClient] = useState([]);
  const handleSubmit = () => {
    if (label && trunkServerName) {
      updateTrunk(userInfo.token, {
        _id: props.data._id,
        label: label,
        trunkServerName: trunkServerName,
        client: idClient == "null" ? null : idClient,
      })
        .then((res) => {
          console.log(res);
          if (res.data) {
            const newCell = {
              ...res.data,
              nbNumero:
                res.data.numeros?.length > 0 ? res.data.numeros?.length : 0,
              nbAlerte:
                res.data.todayAlerts?.length > 0
                  ? res.data.todayAlerts?.length
                  : 0,
            };
            props.cell.getRow().update(newCell);
            addToast("Modification trunk avec succès", {
              appearance: "success",
              autoDismiss: true,
            });
            props.onHide();
          } else {
            alert("Une erreur s'est produit");
            props.onHide();
          }
        })
        .catch((err) => {
          console.log(err);
          addToast("Une erreur s'est produite", {
            appearance: "error",
            autoDismiss: true,
          });
          props.onHide();
        });
    } else alert("veuillez insérer un label et un nom de serveur");
  };

  useEffect(() => {
    if (data.agentsWithDetails && data.agentsWithDetails.length > 0) {
      console.log(data.agentsWithDetails);
      setDataClient(data.agentsWithDetails);
    }
  }, [data]);
  useEffect(() => {
    if (props.data) {
      setLabel(props.data.label ? props.data.label : "");
      setTunkServerName(
        props.data.trunkServerName ? props.data.trunkServerName : ""
      );
    }
  }, [props.data]);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-create-budget"
      >
        <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Modification trunk</h5>
          <button
            className="btn"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            <i class="bi bi-x"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body className="modal-create-budget-body">
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Trunk server name</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Numéro"
                  defaultValue={trunkServerName}
                  onChange={(ev) => setTunkServerName(ev.target.value)}
                  className="form-control"
                />
              </Row>
            </Form.Group>
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Label</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Numéro"
                  defaultValue={label}
                  onChange={(ev) => setLabel(ev.target.value)}
                  className="form-control"
                />
              </Row>
            </Form.Group>
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Client</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Select
                  size="lg"
                  onChange={(ev) => setIdClient(ev.target.value)}
                >
                  <option value={"null"} key={"store"}>
                    Ne pas attribué
                  </option>
                  {dataClient.map((el, i) => {
                    if (el.role == "client") {
                      return (
                        <option
                          key={i}
                          value={el._id}
                          selected={
                            el._id == props.data?.client?._id ? true : false
                          }
                        >
                          {el.nom ? el.nom : ""} {el.prenom ? el.prenom : ""}{" "}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => {
                props.onHide();
              }}
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn"
              style={{ color: "white", backgroundColor: "#6cc070" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Enregistrer
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalModification;
