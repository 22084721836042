import { configureStore } from "@reduxjs/toolkit";
import clientSlice from "./clientSlice";
import userLoginSlice from "./userLoginSlice";
import campagneSlice from "./campagneSlice";
import cdrSlice from "./cdrSlice";
import numeroSlice from "./numeroSlice";
import demandeSlice from "./demandeSlice";
import trunkSlice from "./trunkSlice";
const store = configureStore({
  reducer: {
    listClient: clientSlice,
    userLogin:userLoginSlice,
    listCampagne:campagneSlice,
    listAppel:cdrSlice,
    listNumero:numeroSlice,
    listDemande:demandeSlice,
    listTrunk:trunkSlice
  },
});
export default store;
