import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";
export const getListNum = createAsyncThunk("numero/list", async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros`,
      config
    );
    return data?.data ? data.data : [];
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});
export const addNum = async (token, info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
};
export const updateNum = async (token, info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/${info._id}`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
};
export const updateAudioOneNumero = async (id, audio, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/importAudioForVoiceMailPrompt/${id}`,
      audio,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
const initialState = {
  data: { list: [] },
  statusListNum: statusCode.IDLE,
  error: "",
};
const numeroSlice = createSlice({
  name: "numero",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListNum.pending, (state, action) => {
        state.statusListNum = statusCode.LOADING;
      })
      .addCase(getListNum.fulfilled, (state, action) => {
        state.data.list = action.payload;
        state.statusListNum = statusCode.IDLE;
      })
      .addCase(getListNum.rejected, (state, action) => {
        console.log(state.error);
        state.statusListNum = statusCode.ERROR;
        state.error = action.error.message;
      });
  },
});

export default numeroSlice.reducer;
