import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";

// Définissez d'abord votre thunk
export const login = createAsyncThunk("user/login", async (info) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/login`,
      { email: info.email, password: info.password },
      config
    );
    return data;
  } catch (err) {
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});

export const addUser = async (token,info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
}
export const updateUser = async (token,info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/${info._id}`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
}
// Ensuite, utilisez `login` dans extraReducers
const initialState = {
  userInfo: localStorage.getItem("userInfos")
    ? JSON.parse(localStorage.getItem("userInfos"))
    : null,
  isLogin: false,
  status: statusCode.IDLE,
  error: "",
};

const userLoginSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    logout(state, payload) {
      localStorage.clear();
      localStorage.removeItem("userInfos");
      state.userInfo = null;
      state.isLogin = false;
      state.status = statusCode.IDLE;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.status = statusCode.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (
          action.payload?.data?.user?.role == "client" ||
          action.payload?.data?.user?.role == "admin"
        ) {
          localStorage.setItem("userInfos", JSON.stringify(action.payload));
          state.userInfo = action.payload;
          state.isLogin = true;
          state.status = statusCode.IDLE;
        } else {
          localStorage.clear();
          localStorage.removeItem("userInfos");
          state.status = statusCode.ERROR;
          state.error = "Vous n'êtes pas autorisé à accéder à cette page";
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.status = statusCode.ERROR;
        state.error = action.error.message
      });
  },
});

export const { logout } = userLoginSlice.actions;
export default userLoginSlice.reducer;
