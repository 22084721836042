import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import { getListAppel } from "../../store/cdrSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import { simpleInput } from "../../components/tabulator/tabulator";
import FluxAppelManuel from "./FluxAppelManuel";
import FiltreAppelManuel from "./FiltreAppelManuel";
import { getListClient } from "../../store/clientSlice";
import moment from "moment";
import { searchOptimizedData } from "../../utils/recherche";
const AppelManuel = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listClient = useSelector((state) => state.listClient);
  const { statusListClient } = listClient;
  const listAppel = useSelector((state) => state.listAppel);
  const { data, error, statusListAppel } = listAppel;
  const [dataRequest, setDataRequest] = useState([]);
  const [dataCell, setDataCell] = useState();

  //filtre
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const [filtreBadge, setFiltreBadge] = useState([]);
  const [filtreAppel, setFiltreAppel] = useState([]);
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [endDate, setEndDate] = useState(startDateFormate);
  const [startDate, setStartDate] = useState(dateFormateeOneWeeBefore);
  const [idUtilisateur, setIdUtilisateur] = useState();
  const fieldsToFilter = ["billsec", "calldate", "dst", "src", "sourceNum",];

  //boutton parametrage
  const buttonParametrage = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-plus";
    btn.appendChild(icon);
    btn.setAttribute("data-bs-toggle", "offcanvas");
    btn.setAttribute("data-bs-target", "#flux");
    btn.addEventListener("click", function () {
      setDataCell(data);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //format badge tabulator
  const statutBtn = (cell) => {
    const value = cell.getValue();
    const icon = document.createElement("icon");
    if (value == 1) {
      icon.className = "bi bi-telephone-inbound-fill";
      icon.style = "color:#6cc070";
    } else if (value == 2) {
      icon.className = "bi bi-telephone-outbound-fill";
      icon.style = "color:#00e7eb";
    } else if (value == 3) {
      icon.className = "bi bi-telephone-x-fill";
      icon.style = "color:#EB455F";
    } else if (value == 4) {
      icon.className = "bi bi-voicemail";
      icon.style = "color:orange";
    } else {
      icon.className = "bi bi-telephone-x-fill";
      icon.style = "color:red";
    }
    return icon;
  };
  //format badge tabulator
  const badgeBtn = (cell) => {
    const value = cell.getValue();
    const icon = document.createElement("icon");
    if (value == 1) {
      icon.className = "bi bi-star";
      icon.style = "color:gray";
    } else if (value == 2) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:#FFC436";
    } else if (value == 3) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:#00e7eb";
    } else if (value == 4) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:orange";
    } else if (value == 5) {
      icon.className = "bi bi-exclamation-circle-fill";
      icon.style = "color:#EB455F";
    } else if (value == 6) {
      icon.className = "bi bi-check-circle-fill";
      icon.style = "color:#6cc070";
    } else {
      icon.className = "bi bi-star";
      icon.style = "color:gray";
    }
    return icon;
  };

  //Colonne react tabulator numero
  const colonneTrunk = [
    { title: "Statut", width: 100, field: "statutAppel", formatter: statutBtn },
    { title: "Source", minWidth: 150, field: "src", formatter: simpleInput },
    {
      title: "Destination",
      minWidth: 150,
      field: "dst",
      formatter: simpleInput,
    },
    {
      title: "Numéro",
      minWidth: 150,
      field: "sourceNum",
      formatter: simpleInput,
    },
    {
      title: "Appel du",
      minWidth: 150,
      field: "calldate",
      formatter: simpleInput,
    },
    { title: "Durée", width: 100, field: "billsec", formatter: simpleInput },
    { title: "Badge", width: 100, field: "badge", formatter: badgeBtn },
    { title: "Plus", width: 100, field: "", formatter: buttonParametrage },
  ];
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    dispatch(getListClient({ token: userInfo.token }));
  }, []);
  useEffect(() => {
    dispatch(
      getListAppel({
        token: userInfo.token,
        query: `sort=-calldate&calldate[gte]=${startDate}&calldate[lte]=${endDate}${
          filtreBadge.length > 0 ? `&badge=${filtreBadge}` : ""
        }${filtreAppel.length > 0 ? `&statutAppel=${filtreAppel}` : ""}${idUtilisateur ? `&utilisateur=${idUtilisateur}` : ""}`,
      })
    );
  }, [startDate, endDate, filtreBadge, filtreAppel,idUtilisateur]);
  useEffect(() => {
    if (data && data?.list?.length > 0) {
      const newArray = data.list?.map((el) => {
        return { ...el };
      });
      console.log(newArray);
      setDataRequest(newArray);
    }else{
        setDataRequest([])
    }
  }, [data]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">
              / Historique appels /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des appels -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} appel(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6cc070 ",
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                data-bs-toggle="offcanvas"
                data-bs-target="#filtre"
              >
                <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
                <span>Filtres</span>{" "}
              </button>
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataRequest,
                      fieldsToFilter
                    );
                    setDataRequest(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListAppel == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneTrunk}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
          <FluxAppelManuel dataCell={dataCell} userInfo={userInfo} />
          <FiltreAppelManuel
            startDate={startDate}
            listClient={listClient.data?.agentsWithDetails ? listClient.data?.agentsWithDetails : [] }
            idUtilisateur={idUtilisateur}
            setIdUtilisateur={setIdUtilisateur}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            filtreBadge={filtreBadge}
            setFiltreBadge={setFiltreBadge}
            filtreAppel={filtreAppel}
            setFiltreAppel={setFiltreAppel}
          />
        </Row>
      </Container>
    </>
  );
};

export default AppelManuel;
