import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";
export const getListDemande = createAsyncThunk("demande/list", async (info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${info.token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes?${info.query}`,
      config
    );
    return data?.data ? data.data : [];
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});
export const updateOneDemande = async (token, info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/${info._id}`,{...info},
      config
    );
    return data;
  } catch (err) {
    return err;
  }
};
const initialState = {
  data: { list: [] },
  statusListDemande: statusCode.IDLE,
  error: "",
};

const demandeSlice = createSlice({
  name: "demande",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListDemande.pending, (state, action) => {
        state.statusListDemande = statusCode.LOADING;
      })
      .addCase(getListDemande.fulfilled, (state, action) => {
        state.data.list = action.payload;
        state.statusListDemande = statusCode.IDLE;
      })
      .addCase(getListDemande.rejected, (state, action) => {
        console.log(action.error);
        state.error = action.error.message;
        state.statusListDemande = statusCode.ERROR;
      });
  },
});

export default demandeSlice.reducer;
