import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";
import { addTrunk } from "../../store/trunkSlice";
const ModalAddTrunk = (props) => {
  const { addToast } = useToasts();
  const listClient = useSelector((state) => state.listClient);
  const { data } = listClient;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [serverTrunkName, setServeurTrunkName] = useState("");
  const [label, setLabel] = useState("");
  const [idClient, setIdClient] = useState();
  const [dataClient, setDataClient] = useState([]);
  const handleSubmit = () => {
    if (label && serverTrunkName) {
      addTrunk(userInfo.token, {
        label: label,
        trunkServerName: serverTrunkName,
        client: idClient,
      })
        .then((res) => {
          addToast("Ajout trunk avec succès", {
            appearance: "success",
            autoDismiss: true,
          });
          props.onHide();
          props.setBool(!props.bool);
        })
        .catch((err) => {
          console.log(err);
          addToast("Une erreur s'est produite", {
            appearance: "error",
            autoDismiss: true,
          });
          props.onHide();
        });
    } else {
      alert("veuillez insérer le label et le nom du serveur");
    }
  };
  useEffect(() => {
    if (data.agentsWithDetails && data.agentsWithDetails.length > 0) {
      console.log(data.agentsWithDetails);
      setDataClient(data.agentsWithDetails);
    }
  }, [data]);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-create-budget"
      >
        <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Ajout trunk</h5>
          <button
            className="btn"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            <i class="bi bi-x"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body className="modal-create-budget-body">
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Nom serveur trunk</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Numéro"
                  defaultValue={serverTrunkName}
                  onChange={(ev) => setServeurTrunkName(ev.target.value)}
                  className="form-control"
                />
              </Row>
            </Form.Group>
            <Form.Group className="mb3">
              <Row>
                <Form.Label>label</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Numéro"
                  defaultValue={label}
                  onChange={(ev) => setLabel(ev.target.value)}
                  className="form-control"
                />
              </Row>
            </Form.Group>
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Client</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Select
                  size="lg"
                  onChange={(ev) => setIdClient(ev.target.value)}
                >
                  <option value={"null"} key={"store"}>
                    Ne pas attribué
                  </option>
                  {dataClient.map((el, i) => {
                    if (el.role == "client") {
                      return (
                        <option key={i} value={el._id}>
                          {el.nom ? el.nom : ""} {el.prenom ? el.prenom : ""}{" "}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => {
                props.onHide();
              }}
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn"
              style={{ color: "white", backgroundColor: "#6cc070" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Enregistrer
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddTrunk;
