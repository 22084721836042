import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";

export const getListAppel = createAsyncThunk("appel/list", async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  };
  try {
    const datas  = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/historyOneUser/all?${data.query}`,
      config
    );
    console.log(datas.data);
    return datas?.data?.data ? datas.data.data : [];
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});

const initialState = {
  data: { list: [] },
  statusListAppel: statusCode.IDLE,
  error: "",
};

const cdrSlice = createSlice({
  name: "appel",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListAppel.pending, (state, action) => {
        state.statusListAppel = statusCode.LOADING;        
      })
      .addCase(getListAppel.fulfilled, (state, action) => {
        state.statusListAppel = statusCode.IDLE;
        state.data.list = action.payload;
      })
      .addCase(getListAppel.rejected, (state, action) => {
        console.log(action.error);
        state.error = action.error.message;
        state.statusListAppel = statusCode.ERROR;
      });
  },
});
export default cdrSlice.reducer;
