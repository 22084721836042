import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import Demande from "./screens/Demande/Demande";
import Numero from "./screens/Numero/Numero";
import Campagne from "./screens/Campagne/Campagne";
import Trunk from "./screens/Trunk/Trunk";
import Cdr from "./screens/cdr/Cdr";
import Header from "./components/Header/Header";
import AppelManuel from "./screens/cdr/AppelManuel";
import { useSelector } from "react-redux";
import "./App.css";
import { ToastProvider } from "react-toast-notifications";
function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { status, error, userInfo } = userLogin;
  window.onerror = function (message, source, lineno, colno, error) {
    // Log ou gérez l'erreur comme nécessaire
    console.error(message, source, lineno, colno, error);

    // Retourne true pour empêcher l'affichage de l'erreur dans l'interface utilisateur
    return true;
  };
  return (
    <ToastProvider>
      <BrowserRouter>
        {userInfo && <Header />}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/demandes" element={<Demande />} />
            <Route path="/numero" element={<Numero />} />
            <Route path="/campagne" element={<Campagne />} />
            <Route path="/Trunk" element={<Trunk />} />
            <Route path="/Historique" element={<AppelManuel />} />
            <Route path="/" element={<Home />} exact />
            <Route path="*" element={<Login />} />
          </Routes>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
