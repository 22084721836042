//GLOBAL
//simple input
const simpleInput = (cell) => {
  const input = document.createElement("input");
  input.type = "text";
  input.style =
    "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
  input.defaultValue = cell.getValue() ? cell.getValue() : '-';
  input.disabled = true;
  const container = document.createElement("div");
  container.style = "display:flex;align-items:center;width:100%";
  container.appendChild(input);
  return container;
};

//boutton assignement with navigate
const buttonAssignment = (cell, navigate) => {
  const data = cell.getData();
  const btn = document.createElement("button");
  btn.className = "btn";
  btn.style = "background-color:#61CE70;color:white";
  const icon = document.createElement("icon");
  icon.className = "bi bi-plus";
  btn.appendChild(icon);
  btn.addEventListener("click", function (ev) {
    navigate(`/agent/${data._id}?from=/`);
  });
  const container = document.createElement("div");
  container.appendChild(btn);
  return container;
};
//boutton assignement with navigate
const buttonParametrage = (cell, setState, setData, setCell) => {
  const btn = document.createElement("button");
  btn.className = "btn";
  btn.style = "background-color:#61CE70;color:white";
  const icon = document.createElement("icon");
  icon.className = "bi bi-plus";
  btn.appendChild(icon);
  btn.addEventListener("click", function (ev) {
    setCell(cell);
    setState(true);
    setData(cell.getData());
  });
  const container = document.createElement("div");
  container.appendChild(btn);
  return container;
};
//boutton modification 
const buttonModification = (cell, setState, setData, setCell) => {
  const btn = document.createElement("button");
  btn.className = "btn";
  btn.style = "background-color:#61CE70;color:white";
  const icon = document.createElement("icon");
  icon.className = "bi bi-pencil-fill";
  btn.appendChild(icon);
  btn.addEventListener("click", function (ev) {
    setCell(cell);
    setState(true);
    setData(cell.getData());
  });
  const container = document.createElement("div");
  container.appendChild(btn);
  return container;
};

//DEMANDES
//colonne Nombre demande
const numberColonneDemande = (cell) => {
  const data = cell.getData();
  if (data.type === "Trunk") {
    const quantiteElement = document.createElement("input");
    quantiteElement.type = "text";
    quantiteElement.disabled = true;
    quantiteElement.style =
      "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
    quantiteElement.defaultValue = `Quantité: ${
      data.quantite ? data.quantite : "---"
    }`;
    const canauxElement = document.createElement("input");
    canauxElement.type = "text";
    canauxElement.disabled = true;
    canauxElement.style =
      "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
    canauxElement.defaultValue = `Canaux: ${data.canaux ? data.canaux : "---"}`;
    const conteneur = document.createElement("div");
    conteneur.style = "display:flex;flex-direction:column";
    conteneur.appendChild(quantiteElement);
    conteneur.appendChild(canauxElement);
    return conteneur;
  } else if (data.type === "Modification Trunk") {
    const champNameTrunk = document.createElement("input");
    champNameTrunk.type = "text";
    champNameTrunk.disabled = true;
    champNameTrunk.style =
      "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
    champNameTrunk.defaultValue = `-${
      data?.trunkId?.label ? data.trunkId.label : "---"
    }`;
    const conteneur = document.createElement("div");
    conteneur.style = "display:flex;height:100%;align-items:center";
    conteneur.appendChild(champNameTrunk);
    return conteneur;
  } else {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  }
};
const simpleInputClientDemande = (cell) => {
  const data = cell.getData();
  const input = document.createElement("input");
  input.type = "text";
  input.style =
    "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
  input.defaultValue = `${data?.client?.nom ? data?.client?.nom : ""} ${
    data?.client?.prenom ? data?.client?.prenom : ""
  }`;
  input.disabled = true;
  const container = document.createElement("div");
  container.style = "display:flex;align-items:center;width:100%";
  container.appendChild(input);
  return container;
};
//colonne statut demande
const statutColonneDemande = (cell) => {
  const simpleInput = document.createElement("span");
  simpleInput.style = `color:${
    cell.getValue() == "Validée"
      ? "green"
      : cell.getValue() == "En cours"
        ? "blue"
        : "red"
  };font-size:16px;font-weight:600`;
  simpleInput.innerText = `${cell.getValue() ? cell.getValue() : "---"}`;
  const container = document.createElement("div");
  container.style =
    "width:100%;height:100%;display:flex;justify-content:center;align-items:center";
  container.appendChild(simpleInput);
  return container;
};

//NUMEROS
const simpleInputClientNumero = (cell) => {
  const data = cell.getData();
  const input = document.createElement("input");
  input.type = "text";
  input.style =
    `width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:${data.clientId ? "blue" : "green"};background-color:white`;
  input.defaultValue = `${
    data.clientId
      ? `${data?.clientId?.nom ? data?.clientId?.nom : ""} ${
          data?.clientId?.prenom ? data?.clientId?.prenom : ""
        }`
      : "Disponible"
  }`;
  input.disabled = true;
  const container = document.createElement("div");
  container.style = "display:flex;align-items:center;width:100%";
  container.appendChild(input);
  return container;
};
export {
  simpleInput,
  buttonAssignment,
  numberColonneDemande,
  simpleInputClientDemande,
  buttonParametrage,
  statutColonneDemande,
  simpleInputClientNumero,
  buttonModification
};
