import React from "react";
import moment from "moment";

const FiltreDemande = (props) => {
  return (
    <>
      <div class="offcanvas offcanvas-end" id="filtreDemande">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Filtre</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="simpleTexte">Type de demande</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <button
              className="btn btn-sm"
              style={
                props.filtreStatut.includes(2)
                  ? { backgroundColor: "#6cc070 ", color: "white" }
                  : { border: "solid #6cc070 1px ", color: "#6cc070 " }
              }
              onClick={(ev) => {
                if (props.filtreStatut.includes(2)) {
                  props.setfiltreStatut(
                    props.filtreStatut.filter((el) => el != 2)
                  );
                } else {
                  props.setfiltreStatut([...props.filtreStatut, 2]);
                }
              }}
            >
              {" "}
              Validée
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreStatut.includes(1)
                  ? { backgroundColor: "#00e7eb ", color: "white" }
                  : { border: "solid #00e7eb 1px ", color: "#00e7eb " }
              }
              onClick={(ev) => {
                if (props.filtreStatut.includes(1)) {
                  props.setfiltreStatut(
                    props.filtreStatut.filter((el) => el != 1)
                  );
                } else {
                  props.setfiltreStatut([...props.filtreStatut, 1]);
                }
              }}
            >
              {" "}
              En cours
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreStatut.includes(4)
                  ? { backgroundColor: "#EB455F ", color: "white" }
                  : { border: "solid #EB455F 1px ", color: "#EB455F " }
              }
              onClick={(ev) => {
                if (props.filtreStatut.includes(4)) {
                  props.setfiltreStatut(
                    props.filtreStatut.filter((el) => el != 4)
                  );
                } else {
                  props.setfiltreStatut([...props.filtreStatut, 4]);
                }
              }}
            >
              {" "}
              Refusée
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreStatut.includes(3)
                  ? { backgroundColor: "orange ", color: "white" }
                  : { border: "solid orange 1px ", color: "orange " }
              }
              onClick={(ev) => {
                if (props.filtreStatut.includes(3)) {
                  props.setfiltreStatut(
                    props.filtreStatut.filter((el) => el != 3)
                  );
                } else {
                  props.setfiltreStatut([...props.filtreStatut, 3]);
                }
              }}
            >
              {" "}
             Annulé
            </button>
          </div>
          <div className="simpleTexte" style={{ marginTop: "20px" }}>
            date
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.startDate && props.startDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setStartDate(newValue);
              }}
            />
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.endDate && props.endDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setEndDate(newValue);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltreDemande;
