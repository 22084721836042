import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import { getListCampagne } from "../../store/campagneSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import {
  simpleInput,
  simpleInputClientDemande,
} from "../../components/tabulator/tabulator";
import { searchOptimizedData } from "../../utils/recherche";
const Campagne = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listCampagne = useSelector((state) => state.listCampagne);
  const { error, data, statusListCampagne } = listCampagne;
  const [dataRequest, setDataRequest] = useState([]);

  //Colonne react tabulator numero
  const colonneCampagne = [
    { title: "Nom", field: "nom", formatter: simpleInput },
    { title: "descritpion", field: "description", formatter: simpleInput },
    { title: "client", field: "", formatter: simpleInputClientDemande },
    { title: "Numeros", field: "numeroAttache", formatter: simpleInput },
    /* { title: "Plus", width: 100, field: "", formatter: (cell,a,b)=>{
      return buttonAssignment(cell,navigate)
    } }, */
  ];
  const fieldsToFilter = ["createdAt", "nom", "prenom", "email", "description"];
  //Récuperation des campagnes
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    dispatch(getListCampagne({ token: userInfo.token }));
  }, []);
  useEffect(() => {
    if (data.list && data.list.length > 0) {
      const newArray = data.list.map((obj) => ({
        ...obj,
        agentsAttache: obj.agents?.length ? obj.agents.length : 0,
        numeroAttache: obj.numeros?.length ? obj.numeros.length : 0,
      }));
      setDataRequest(newArray);
    }
  }, [data]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">
              / Gestion Campagne /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des campagnes -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} campagne(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataRequest,
                      fieldsToFilter
                    );
                    setDataRequest(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListCampagne == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneCampagne}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Campagne;
