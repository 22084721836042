import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import { getListNum } from "../../store/numeroSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import {
  simpleInput,
  simpleInputClientNumero,
  buttonParametrage,
  buttonModification,
} from "../../components/tabulator/tabulator";
import ModalAddNum from "./ModalAddNum";
import ModalModification from "./ModalModification";
import { getListClient } from "../../store/clientSlice";
import { searchOptimizedData } from "../../utils/recherche";

const Numero = () => {
  const { addToast } = useToasts();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listNumero = useSelector((state) => state.listNumero);
  const { statusListNum, data, error } = listNumero;
  const [addNum, setAddNum] = useState(false);
  const [modifNum, setModifNum] = useState(false);
  const [dataRequest, setDataRequest] = useState([]);
  const [dataToUpdate, setDataToUpdate] = useState();
  const [cell, setCell] = useState();
  const [bool, setBool] = useState(false);
  //Colonne react tabulator numero
  const colonneNumero = [
    { title: "Créer le", field: "createdAt", formatter: simpleInput },
    { title: "Numéro", field: "numero", formatter: simpleInput },
    { title: "client", field: "clientId", formatter: simpleInputClientNumero },
    {
      title: "plus",
      file: "",
      width: 120,
      formatter: (cell, a, b) => {
        return buttonParametrage(cell, setModifNum, setDataToUpdate, setCell);
      },
    },
  ];
  const fieldsToFilter = ["createdAt", "email", "prenom", "numero"];
  //Recuperation des numéros
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    dispatch(getListNum({ token: userInfo.token }));
  }, [bool]);
  useEffect(() => {
    if (data && data?.list?.length > 0) {
      const newArray = data.list?.map((el) => {
        return { ...el };
      });
      setDataRequest(newArray);
    }
  }, [data]);
  useEffect(() => {
    dispatch(getListClient({ token: userInfo.token }));
  }, []);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize" data-testid='test1'>
              / Gestion Numéro /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des numéros -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} numéro(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                className="btn btn-sm btn-primary"
                style={{
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(ev) => setAddNum(true)}
              >
                <i class="bi bi-plus" style={{ fontSize: "14px" }}></i>
                <span>Ajouter</span>{" "}
              </button>
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataRequest,
                      fieldsToFilter
                    );
                    setDataRequest(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListNum == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneNumero}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
          {addNum && (
            <>
              <ModalAddNum
                show={addNum}
                onHide={() => {
                  setAddNum(false);
                }}
                bool={bool}
                setBool={setBool}
              />
            </>
          )}
          {modifNum && (
            <>
              <ModalModification
                show={modifNum}
                onHide={() => {
                  setModifNum(false);
                }}
                data={dataToUpdate}
                cell={cell}
                bool={bool}
                setBool={setBool}
              />
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Numero;
