import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import { getListDemande } from "../../store/demandeSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import moment from "moment";
import {
  simpleInput,
  numberColonneDemande,
  simpleInputClientDemande,
  buttonParametrage,
  statutColonneDemande,
} from "../../components/tabulator/tabulator";
import Message from "../../components/Message";
import { searchOptimizedData } from "../../utils/recherche";
import ModalConfirmationDemande from "./ModalConfirmationDemande";
import FiltreDemande from "./FiltreDemande";
const Demande = () => {
  const { addToast } = useToasts();
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [endDate, setEndDate] = useState(startDateFormate);
  const [startDate, setStartDate] = useState(dateFormateeOneWeeBefore);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [parametre, setParametre] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listDemande = useSelector((state) => state.listDemande);
  const { statusListDemande, data, error } = listDemande;
  const [dataRequest, setDataRequest] = useState([]);
  const [cell, setCell] = useState();
  const [dataToUpdate, setDataToUpdate] = useState();
  //Liste des champs à filtrer
  const fieldsToFilter = [
    "createdAt",
    "statut",
    "client",
    "nom",
    "prenom",
    "quantite",
    "trunkId",
    "label",
    "type",
  ];
  const [filtreStatut, setFiltreStatut] = useState([]);
  //Colonne react tabulator numero
  const colonneDemande = [
    { title: "Date", field: "createdAt", formatter: simpleInput },
    { title: "Client", field: "client", formatter: simpleInputClientDemande },
    { title: "Type de demande", field: "type", formatter: simpleInput },
    { title: "Nombre", field: "quantite", formatter: numberColonneDemande },
    {
      title: "Status",
      field: "statut",
      width: 120,
      formatter: statutColonneDemande,
    },
    {
      title: "Plus",
      field: "",
      width: 120,
      formatter: (cell, a, b) => {
        return buttonParametrage(cell, setParametre, setDataToUpdate, setCell);
      },
    },
  ];
  //Recuperation des demandes
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    //vérification si la da de debut est bien inférieur à la date de fin
    if (moment(startDate).isAfter(endDate)) {
      alert("La date de début ne peut pas être après la date de fin!");
    } else {
      dispatch(
        getListDemande({
          token: userInfo.token,
          query: `createdAt[gte]=${startDate}&createdAt[lte]=${endDate}${
            filtreStatut.length > 0 ? `&statut=${filtreStatut}` : ""
          }`,
        })
      );
    }
  }, [filtreStatut, endDate, startDate]);

  //formattage données
  useEffect(() => {
    if (data && data?.list?.length > 0) {
      const newArray = data.list?.map((obj) => ({
        ...obj,
        type:
          obj.type === 1
            ? "Numéro"
            : obj.type === 2
              ? "Accés"
              : obj.type === 3
                ? "Trunk"
                : obj.type === 4
                  ? "Modification Trunk"
                  : obj.type,
        statut:
          obj.statut === 1
            ? "En cours"
            : obj.statut === 2
              ? "Validée"
              : obj.statut === 3
                ? "Annulée"
                : obj.statut === 4
                  ? "Refusée"
                  : obj.statut,
      }));
      setDataRequest(newArray);
    } else {
      setDataRequest([]);
    }
  }, [data]);

  //gestion erreur
  useEffect(() => {
    if (statusListDemande == statusCode.ERROR) {
      addToast(`${error}`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [statusListDemande]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">
              / Gestion Demande /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des demandes -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} demande(s)
              </span>
            </div>

            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#6cc070 ",
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                data-bs-toggle="offcanvas"
                data-bs-target="#filtreDemande"
              >
                <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
                <span>Filtres</span>{" "}
              </button>
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataRequest,
                      fieldsToFilter
                    );
                    setDataRequest(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListDemande == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneDemande}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
              {dataRequest.length < 1 && (
                <>
                  <Message>Aucune demande</Message>
                </>
              )}
            </>
          )}
          {parametre && (
            <ModalConfirmationDemande
              show={parametre}
              onHide={() => {
                setParametre(false);
              }}
              data={dataToUpdate}
              cell={cell}
            />
          )}
          <FiltreDemande
            filtreStatut={filtreStatut}
            setfiltreStatut={setFiltreStatut}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Row>
      </Container>
    </>
  );
};

export default Demande;
