import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";

export const getListCampagne = createAsyncThunk(
  "campagne/list",
  async (data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    };
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/`,
        config
      );
      return data?.data ? data.data : [];
    } catch (err) {
      console.log(err);
      throw new Error(
        err.response?.data?.message
          ? err.response.data.message
          : "Une erreur s'est produite"
      );
    }
  }
);

const initialState = {
  data: { list: [] },
  statusListCampagne: statusCode.IDLE,
  error: "",
};

const campagneSlice = createSlice({
  name: "campagne",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCampagne.pending, (state, action) => {
        state.statusListCampagne = statusCode.LOADING;
      })
      .addCase(getListCampagne.fulfilled, (state, action) => {
        state.data.list = action.payload;
        state.statusListCampagne = statusCode.IDLE;
      })
      .addCase(getListCampagne.rejected, (state, action) => {
        console.log(action.error);
        state.error = action.error.message;
        state.statusListCampagne = statusCode.ERROR;
      });
  },
});
export default campagneSlice.reducer;
