import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";
import { updateNum, updateAudioOneNumero } from "../../store/numeroSlice";
const ModalModification = (props) => {
  const { addToast } = useToasts();
  const listClient = useSelector((state) => state.listClient);
  const { data } = listClient;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [numero, setNumero] = useState();
  const [idClient, setIdClient] = useState();
  const [dataClient, setDataClient] = useState([]);
  const [audioRepondeur, setAudioRepondeur] = useState();
  const [isAudioRepondeur, setIsAudioRepondeur] = useState(false);

  const handleSubmit = () => {
    if (numero) {
      /* updateOneNumero(props.info) */
      if (audioRepondeur) {
        const formData = new FormData();
        formData.append("audio", audioRepondeur);
        updateAudioOneNumero(props.data?._id, formData, userInfo.token)
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      updateNum(userInfo.token, {
        numero: numero,
        clientId: idClient == "null" ? null : idClient,
        _id: props.data?._id,
      })
        .then((res) => {
          addToast("Modification numéro avec succès", {
            appearance: "success",
            autoDismiss: true,
          });
          props.setBool(!props.bool);
          props.onHide();
        })
        .catch((err) => {
          console.log(err);
          addToast("Une erreur s'est produite", {
            appearance: "error",
            autoDismiss: true,
          });
          props.onHide();
        });
    } else {
      alert("veuillez insérer un numéro");
    }
  };

  useEffect(() => {
    if (data.agentsWithDetails && data.agentsWithDetails.length > 0) {
      setDataClient(data.agentsWithDetails);
    }
  }, [data]);
  useEffect(() => {
    if (props.data) {
      setNumero(props.data?.numero);
      setIdClient(props.data?.clientId?._id ? props.data?.clientId?._id : null);
      if (props.data.noAnswerAudioFilename) {
        setIsAudioRepondeur(true);
      } else {
        setIsAudioRepondeur(false);
      }
    }
  }, [props.data]);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-create-budget"
      >
        <Modal.Header
          className="modal-create-budget-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Ajout numéro</h5>
          <button
            className="btn"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            <i class="bi bi-x"></i>
          </button>
        </Modal.Header>
        <Form>
          <Modal.Body className="modal-create-budget-body">
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Numéro</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Control
                  required
                  type="text"
                  placeholder="Numéro"
                  defaultValue={numero}
                  onChange={(ev) => setNumero(ev.target.value)}
                  className="form-control"
                />
              </Row>
            </Form.Group>
            <Form.Group className="mb3">
              <Row>
                <Form.Label>Client</Form.Label>
              </Row>
              <Row style={{ padding: "10px 20px" }}>
                <Form.Select
                  size="lg"
                  onChange={(ev) => setIdClient(ev.target.value)}
                >
                  <option value={"null"} key={"store"}>
                    Ne pas attribué
                  </option>
                  {dataClient.map((el, i) => {
                    if (el.role == "client") {
                      return (
                        <option
                          key={i}
                          value={el._id}
                          selected={
                            el._id == props.data?.clientId?._id ? true : false
                          }
                        >
                          {el.nom ? el.nom : ""} {el.prenom ? el.prenom : ""}{" "}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Row>
              {isAudioRepondeur ? (
                <>
                  <div
                    class="mb-3 mt-3"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label class="form-label">
                      Fichier audio pour le répondeur
                    </label>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        titre:{" "}
                        {props.data?.noAnswerAudioFilename
                          ? props.data?.noAnswerAudioFilename
                          : "Non défini"}
                      </span>
                      <i
                        class="bi bi-pencil-square"
                        onClick={(ev) => setIsAudioRepondeur(false)}
                      ></i>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    class="mb-3 mt-3"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label class="form-label">
                      Fichier audio pour le répondeur (.wav,.mp3)
                    </label>
                    <input
                      type="file"
                      onChange={(ev) => {
                        ev.preventDefault();
                        setAudioRepondeur(ev.target.files[0]);
                      }}
                      className="form-control"
                      accept=".wav,.mp3"
                    />
                  </div>
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              class="btn btn-danger"
              onClick={() => {
                props.onHide();
              }}
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn"
              style={{ color: "white", backgroundColor: "#6cc070" }}
              onClick={() => {
                handleSubmit();
              }}
            >
              Enregistrer
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ModalModification;
