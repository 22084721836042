import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row, Table } from "react-bootstrap";
import Card from "../../components/Card/Card";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Loader from "../../components/Loader";
import { getListClient } from "../../store/clientSlice";
import { useToasts } from "react-toast-notifications";
import "./style.css";
import { ReactTabulator } from "react-tabulator";
import ModalAddUser from "./ModalAddUser";
import ModalUpdateUser from "./ModalUpdateUser";
import { searchOptimizedData } from "../../utils/recherche";
import {
  simpleInput,
  buttonParametrage
} from "../../components/tabulator/tabulator";
import statusCode from "../../utils/statusCode";
//page d'accueil
const Home = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listClient = useSelector((state) => state.listClient);
  const { statusListClient, data, error } = listClient;
  const [dataClient, setDataClient] = useState([]);
  const [addUser, setAddUser] = useState(false); 
  const [bool, setBool] = useState(false);
  const [dataToUpdate,setDataUpdate] = useState()
  const [cell,setCell]=useState()
  const [updateUser,setUpdateUser] = useState(false)
  //Colonne react tabulator numero
  const colonneClient = [
    { title: "Nom", minWidth: 200, field: "nom", formatter: simpleInput },
    { title: "Prenom", minWidth: 200, field: "prenom", formatter: simpleInput },
    { title: "email", minWidth: 200, field: "email", formatter: simpleInput },
    {
      title: "Campagnes",
      field: "nbCampagne",
      width: 120,
      formatter: simpleInput,
    },
    { title: "Rôle", field: "role", width: 120, formatter: simpleInput },
    { title: "Numeros", field: "nbNumero", width: 120, formatter: simpleInput },
    {
      title: "Plus",
      width: 100,
      field: "",
      formatter: (cell, formatterParams, onRendered) => {
        return buttonParametrage(cell,setUpdateUser,setDataUpdate,setCell)
      },
    },
  ];
  const fieldsToFilter = [
    "createdAt",
    "nom",
    "prenom",
    "email",
    "nbAppel",
    "role",
    "nbCampagne",
    "nbNumero",
  ];
  //Recuperation des utilisateurs
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
    dispatch(getListClient({ token: userInfo.token }));
  }, [bool]);
  useEffect(() => {
    if (data && data?.agentsWithDetails?.length > 0) {
      const newArray = data.agentsWithDetails?.map((el) => {
        return { ...el };
      });
      setDataClient(newArray);
    }
  }, [data]);
  useEffect(() => {
    if (statusListClient == statusCode.ERROR) {
      addToast(`${error}`, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [statusListClient]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">
              / Gestion Utilisateur /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des utilisateurs -{" "}
                {dataClient?.length ? dataClient.length : "0"} utilisateur(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <button
                className="btn btn-sm btn-primary"
                style={{
                  color: "white",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={(ev) => setAddUser(true)}
              >
                <i class="bi bi-plus" style={{ fontSize: "14px" }}></i>
                <span>Ajouter</span>{" "}
              </button>
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {
                    const dataSearch = searchOptimizedData(
                      ev.target.value,
                      dataClient,
                      fieldsToFilter
                    );
                    setDataClient(dataSearch);
                  }}
                />
              </span>
            </div>
          </div>
          {statusListClient == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataClient}
                columns={colonneClient}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
          {addUser && (
            <>
              <ModalAddUser
                show={addUser}
                onHide={() => {
                  setAddUser(false);
                }}
                bool={bool}
                setBool={setBool}
              />
            </>
          )}
          {
            updateUser && (
              <>
              <ModalUpdateUser
              show={updateUser}
              onHide={() => {
                setUpdateUser(false);
              }}
              data={dataToUpdate}
              cell={cell}
              />
              </>
            )
          }
        </Row>
      </Container>
    </>
  );
};

export default Home;
