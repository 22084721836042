import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import statusCode from "../utils/statusCode";

export const getListTrunk = createAsyncThunk("trunk/list", async (info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${info.token}`,
    },
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/trunks?${info.query}`,
      config
    );
    return data?.data ? data.data : [];
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
});
export const updateTrunk = async (token, info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/trunks/${info._id}`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
};
export const addTrunk = async (token, info) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/trunks`,
      { ...info },
      config
    );
    return data;
  } catch (err) {
    console.log(err);
    throw new Error(
      err.response?.data?.message
        ? err.response.data.message
        : "Une erreur s'est produite"
    );
  }
};
const initialState = {
  data: { list: [] },
  statusListTrunk: statusCode.IDLE,
  error: "",
};

const trunkSlice = createSlice({
  name: "trunk",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListTrunk.pending, (state, action) => {
        state.statusListTrunk = statusCode.LOADING;
      })
      .addCase(getListTrunk.fulfilled, (state, action) => {
        state.data.list = action.payload;
        state.statusListTrunk = statusCode.IDLE;
      })
      .addCase(getListTrunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusListTrunk = statusCode.ERROR;
      });
  },
});
export default trunkSlice.reducer;
