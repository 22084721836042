import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";
import { updateUser } from "../../store/userLoginSlice";
import Loader from "../../components/Loader";

//modification ustilisateur

const ModalUpdateUser = (props) => {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [nom, setNom] = useState(props.data?.nom ? props.data.nom : "");
  const [prenom, setPrenom] = useState(
    props.data?.prenom ? props.data.prenom : ""
  );
  const [email, setEmail] = useState(props.data?.email ? props.data.email : "");
  const [role, setRole] = useState(props.data?.role ? props.data.role : "");
  const [extension, setExtenstion] = useState(
    props.data?.extension ? props.data.extension : ""
  );
  const [mdp, setMpd] = useState("");
  const [confirmMdp, setConfirmMdp] = useState("");
  const handleSubmit = () => {
    setLoading(true);
    let data;
    if (mdp && confirmMdp) {
      data = {
        nom: nom,
        prenom: prenom,
        email: email,
        role: role,
        extension: extension,
        _id: props.data?._id,
        password: mdp,
        passwordConfirm: confirmMdp,
      };
      updateUser(userInfo.token, data)
        .then((res) => {
          if (res.data) {
            props.onHide();
            props.cell.getRow().update(res.data);
            addToast(`Modification avec succès`, {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          props.onHide();
          addToast(
            `Une erreur s'est produit durant la modification de l'utilisateur`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        })
        .finally((res) => {
          setLoading(false);
        });
    } else {
      data = {
        nom: nom,
        prenom: prenom,
        email: email,
        role: role,
        extension: extension,
        _id: props.data?._id,
      };
      updateUser(userInfo.token, data)
        .then((res) => {
          if (res.data) {
            props.onHide();
            props.cell.getRow().update(res.data);
            addToast(`Modification avec succès`, {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          props.onHide();
          addToast(
            `Une erreur s'est produit durant la modification de l'utilisateur`,
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-create-budget"
      >
        <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Modification utilisateur</h5>
          <button
            className="btn"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            <i class="bi bi-x"></i>
          </button>
        </Modal.Header>
        {loading ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <Loader />
            </div>
          </>
        ) : (
          <>
            <form
              onSubmit={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                handleSubmit();
              }}
            >
              <Modal.Body className="modal-create-budget-body">
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Nom</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      required
                      type="text"
                      placeholder="Nom"
                      defaultValue={nom}
                      onChange={(ev) => setNom(ev.target.value)}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Prenom</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      required
                      type="text"
                      placeholder="Prénom"
                      defaultValue={prenom}
                      onChange={(ev) => {
                        setPrenom(ev.target.value);
                      }}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Email</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      required
                      type="mail"
                      placeholder="Email"
                      defaultValue={email}
                      onChange={(ev) => {
                        setEmail(ev.target.value);
                      }}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Rôle (agent/client)</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      required
                      type="Rôle"
                      placeholder="Rôle"
                      defaultValue={role}
                      onChange={(ev) => {}}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Extension</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      required
                      type="mail"
                      placeholder="Extension"
                      defaultValue={extension}
                      onChange={(ev) => {
                        setExtenstion(ev.target.value);
                      }}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Mot de passe</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      type="text"
                      placeholder="mot de passe"
                      defaultValue={""}
                      onChange={(ev) => {
                        setMpd(ev.target.value);
                      }}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
                <Form.Group className="mb3">
                  <Row>
                    <Form.Label>Confirmer le mot de passe</Form.Label>
                  </Row>
                  <Row style={{ padding: "10px 20px" }}>
                    <input
                      type="mail"
                      placeholder="Confirmer le mot de passe"
                      defaultValue={""}
                      onChange={(ev) => {
                        setConfirmMdp(ev.target.value);
                      }}
                      className="form-control"
                    />
                  </Row>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={() => {
                    props.onHide();
                  }}
                >
                  Fermer
                </button>
                <button
                  type="submit"
                  class="btn"
                  style={{ color: "white", backgroundColor: "#6cc070" }}
                >
                  Enregistrer
                </button>
              </Modal.Footer>
            </form>
          </>
        )}
      </Modal>
    </>
  );
};

export default ModalUpdateUser;
